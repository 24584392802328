<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <!--<div><router-link to="/Admin/adv/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>
-->
                    <div style="width:160px;">
                        <el-select v-model="where.F_TI_ZHIFZT" placeholder="选择支付状态">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="未支付" value="1"></el-option>
                            <el-option label="支付成功" value="2"></el-option>
                            <el-option label="支付失败" value="3"></el-option>
                        </el-select>
                    </div>
                    <div style="width:160px;">
                        <el-select v-model="where.F_TI_DINGDZT" placeholder="选择订单状态">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="待付款" value="1"></el-option>
                            <el-option label="待发货" value="2"></el-option>
                            <el-option label="待收货" value="3"></el-option>
                            <el-option label="已完成" value="4"></el-option>
                        </el-select>
                    </div>

                    <div ><el-input   v-model="where.F_VR_XUEYXM" placeholder="学员姓名"></el-input></div>
                    <div><el-button icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button></div>


                </div>

                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="F_IN_ID" label="#"  width="50px"></el-table-column>

                    <el-table-column prop="F_VR_XUEYXM" label="学员姓名" width="80px"></el-table-column>
                    <el-table-column prop="F_VR_XUEJH" label="学籍号" width="80px"></el-table-column>
                    <!--<el-table-column prop="F_IN_SIRDZSP_ID" label="商品"></el-table-column>-->
                    <el-table-column prop="F_VR_DINGDH" label="订单号" width="80px"></el-table-column>
                    <el-table-column prop="F_IN_SHUL" label="数量" width="50px"></el-table-column>
                    <el-table-column prop="F_VR_SHANGPZE" label="商品总额" width="70px"></el-table-column>

                    <el-table-column prop="F_VR_SHIJZF" label="实际支付" width="70px"></el-table-column>
                    <el-table-column label="支付状态" width="80px">
                        <template slot-scope="scope">
                            <el-tag type="danger" v-if="scope.row.F_TI_ZHIFZT==1">未支付</el-tag>
                            <el-tag type="success" v-else-if="scope.row.F_TI_ZHIFZT==2">支付成功</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.F_TI_ZHIFZT==3">支付失败</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单状态" width="80px">
                        <template slot-scope="scope">
                            <el-tag type="danger" v-if="scope.row.F_TI_DINGDZT==1">待付款</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.F_TI_DINGDZT==2">待发货</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.F_TI_DINGDZT==3">待收货</el-tag>
                            <el-tag type="success" v-else-if="scope.row.F_TI_DINGDZT==4">已完成</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="F_VR_SHOUJ" label="手机号码" width="100px"></el-table-column>
                    <el-table-column prop="F_VR_XIANGXDZ" label="收货地址"></el-table-column>
                    <el-table-column prop="F_VR_MAIJLY" label="买家留言"></el-table-column>

                    <el-table-column prop="F_IN_CHUANGJSJ" label="创建时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_IN_CHUANGJSJ<=0"> - </div>
                            <div v-else>{{scope.row.F_IN_CHUANGJSJ|formatDate}}</div>
                        </template>
                    </el-table-column>

                   <!-- <el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'adv_form',params:{id:scope.row.id}})">编辑</el-button>
                            &lt;!&ndash; <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> &ndash;&gt;
                        </template>
                    </el-table-column>-->
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          adv_position_id:0,
          where:{
          },
          info:[],
      };
    },
    watch: {},
    computed: {},
    methods: {
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.F_IN_ID);
            });
            this.select_id = ids.join(',');
        },
        get_adv_list:function(){
            this.where.page = this.current_page;
            this.$get(this.$api.getSirdzddList,this.where).then(res=>{
                this.page_size = res.data.per_page;
                this.total_data = res.data.total;
                this.current_page = res.data.current_page;
                this.list = res.data.data;
            })

        },
        // 删除处理
        del:function(F_IN_ID){
            if(this.$isEmpty(F_IN_ID)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delSirdzdd,{F_IN_ID:F_IN_ID}).then(res=>{
                if(res.code == 200){
                    this.get_adv_list();
                    return this.$message.success("删除成功");
                }else{
                    return this.$message.error("删除失败");
                }
            });
        },
        current_change:function(e){
            this.current_page = e;
            this.get_adv_list();
        },
    },
    created() {
        this.get_adv_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
</style>